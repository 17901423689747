/** @jsx jsx */
import { jsx, css } from 'theme-ui'
import React, { createContext } from 'react'
import { ThemeProvider, Container, Flex, Box } from 'theme-ui'
import {
  NormalizeStyles,
  SanitizeStyles,
  OptimizeStyles,
} from '@alexseitsinger/react-emotion-global-styles'
import { withOrientationChange } from 'react-device-detect'
import theme from 'theme'
import Footer from './Footer'
import Header from './Header'
import GlobalBackground from './GlobalBackground'
import SubPageLeftRightBtns from './ui/SubPageLeftRightBtns'
import emotionReset from 'emotion-reset'
import { Global } from '@emotion/core'
import { AnimatePresence } from 'framer-motion'
import ViewLarger from './ViewLarger'
import ProfileResults from './ProfileResults'
import { CaseStudyIds, map } from 'content/profile/caseStudies'
import { useDispatch } from 'react-redux'
import useWindowSize from 'hooks/useWindowSize'
import APIProvider from './APIProvider'

interface LayoutProps {
  children?: React.ReactNode
  location?: Location
}

export const Context = createContext({
  location: null,
  isLandscape: false,
})

const Layout: React.FC<LayoutProps> = ({ location, children }) => {
  const resizeWindow = () => {
    const windowHeight = window.innerHeight
    const pageHeight = 960
    const zoom = windowHeight / pageHeight
    document.body.style.zoom = zoom.toString()
  }
  if (typeof window !== 'undefined' && window.document) {
    document.body.style.overflow = 'auto'
    window.addEventListener('resize', resizeWindow)
    resizeWindow()
  }

  // const { isLandscape } = useWindowSize()
  const isLandscape = true
  return (
    <ThemeProvider theme={theme}>
      <NormalizeStyles />
      <SanitizeStyles />
      <OptimizeStyles />
      <Context.Provider value={{ location, isLandscape }}>
        <APIProvider>
          <div
            className="viewport-container"
            sx={{
              width: '1280px',
              height: '960px',
              position: 'absolute',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'rgba(0, 0, 0, .2)',
              // overflow: 'hidden',
              left: '50%',
              top: '0',
              transform: 'translate(-50%, 0%)',
            }}
          >
            <GlobalBackground
              location={location}
              sx={{
                display: 'block',
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: -10,
              }}
            />
            <Flex
              sx={{
                // width: 450,
                // height: 800,
                // width: '100vw',
                // height: '100vh',
                flexDirection: 'column',
                // borderWidth: 1,
                // borderTopWidth: 0,
                // borderBottomWidth: 0,
                // borderStyle: 'solid',
                // borderColor: 'rgba(0, 0, 0, .2)',
                width: '100%',
                height: '100%',
                mx: 'auto',
                overflow: 'hidden',
              }}
            >
              <header
                sx={{
                  display: 'flex',
                  // borderWidth: 2,
                  // borderStyle: 'solid',
                  // borderColor: 'green',
                  width: '100%',
                  height: '13.5%',
                }}
              >
                <Header location={location} />
              </header>
              <main
                sx={{
                  // borderWidth: 2,
                  // borderStyle: 'solid',
                  // borderColor: '#ff0000',
                  width: '100%',
                  flex: '1 1 100%',
                  // width: 'auto',
                  // height: '100vh',
                }}
              >
                <div
                  sx={{
                    position: 'relative',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>
                  <SubPageLeftRightBtns location={location} />
                </div>
              </main>

              <footer
                sx={{
                  display: 'flex',
                  // borderWidth: 2,
                  // borderStyle: 'solid',
                  // borderColor: 'blue',
                  width: '100%',
                }}
              >
                <Footer location={location} />
              </footer>
            </Flex>
            <ViewLarger />
          </div>
        </APIProvider>
      </Context.Provider>
    </ThemeProvider>
  )
}

export default Layout
