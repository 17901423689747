export interface Step {
  id: string
  label: string
  heading?: string
  subhead?: string
  type: string
  options: StepNode[]
  availableOptions: string[]
}

export interface StepNode {
  id: string
  label: string
  type: string
  rules: string[][]
  media?: React.FC<StepNodeComponent> | string
  thumb?: React.FC<StepNodeComponent> | string
  detail?: React.FC<StepNodeComponent> | string
}

export interface MediaPack {
  readMore?: Function
  index: number
}

export interface StepNodeComponent {
  children?: React.ReactNode
  className?: string
  delay?: number
}

export enum NodeTypes {
  ANALYTE = 'analyte',
  SAMPLE = 'sample',
  REGION = 'region',
  WORKFLOW = 'workflow',
  ASSAY = 'assay',
  CASE_STUDY = 'case-study',
}
