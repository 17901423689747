import { action } from 'typesafe-actions'
import { ActionTypes } from './types'

// import {
//   LayoutActionTypes,
//   ThemeColors,
//   HeaderIsStuck,
//   MenuIsExpanded,
// } from './types'

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.

export const setStepIndex = (index: number) =>
  action(ActionTypes.SET_STEP_INDEX, index)

export const nextStepIndex = () => action(ActionTypes.NEXT_STEP)

export const previousStepIndex = () => action(ActionTypes.PREVIOUS_STEP)

export const setStepSelection = (index: number, id: string) =>
  action(ActionTypes.SET_STEP_SELECTION, { index, id })
