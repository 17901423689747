/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import ZoomableImage from 'components/ZoomableImage'

import img1 from './rimm-detail1-2.jpg'
import img2 from './rimm-detail3.jpg'
import img3 from './rimm-detail4-full.jpg'
import img4 from './rimm-detail5-full.jpg'
import img5 from './rimm-detail6-7.jpg'
import fadeAnimations from 'animations/fade'
import ReadMoreLink from 'components/CaseStudyDetails/ReadMoreLink'
import { MediaPack } from 'content/profile/types'
import { Context } from 'components/Layout'

const FrontPage: React.FC<MediaPack> = ({
  readMore,
  index,
}): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            h5: { p: '1rem' },
            width: '100%',
          }}
          {...fadeAnimations}
        >
          <h5>Identification of Predictive Biomarkers in Melanoma</h5>
          <div sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <div
              sx={{
                textAlign: 'center',
                fontSize: '.7rem',
                mr: '2rem',
                width: '65%',
                display: 'flex',
                flexDirection: 'column',
                img: { width: '65%' },
              }}
            >
              <ZoomableImage preview={img1} src={img1} />
            </div>
            <div
              sx={{
                color: 'primary',
                fontSize: '1rem',
                textAlign: 'left',
                width: '50%',
              }}
            >
              <p
                sx={{
                  fontSize: '1rem',
                  paddingTop: '3rem',
                }}
              >
                David Rimm, MD, PhD Director of Pathology Tissue Services
                Director of Translational Pathology Yale University <br />
                <br />
                Using tissue microarrays from pre-treatment biopsies, DSP
                reveals novel predictive biomarkers throughout various tissue
                segments
                <br />
                <br />
              </p>
              {/* This link will be displayed if the 'readMore' function is passed.
             It is how you open up the pages defined below */}
              {readMore && <ReadMoreLink readMore={readMore} />}
            </div>
          </div>
        </motion.div>
      )}
    </Context.Consumer>
  )
}

export const Page1: React.FC = (): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{ display: 'flex', flexDirection: 'column' }}
          {...fadeAnimations}
        >
          <div
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h3 sx={{ color: 'primary', paddingLeft: '3rem' }}>
              Your <span className="highlighted">RNA</span> Case Study Endpoint:{' '}
              <span className="highlighted">Predictive Biomarkers</span>
            </h3>
            <br />
            <div sx={{ paddingLeft: '3rem' }}>
              <h4 sx={{ color: 'black' }}>Mask strategy for each TMA core</h4>
              <br />
              <p sx={{ fontSize: '1em' }}>
                Using tissue microarrays from pre-treatment biopsies, DSP
                segments each core into distinct segments.
              </p>
              <br />
              <br />
              <h5 sx={{ color: 'primary', fontSize: '1rem' }}>
                Serial masks for each TMA core:
              </h5>
              <br />
              <ol
                sx={{
                  listStyle: 'decimal',
                  lineHeight: '2rem',
                  paddingLeft: '3rem',
                  paddingBottom: '3rem',
                  fontSize: '1rem',
                }}
              >
                <li>CD68 (macrophages)</li>
                <li>CD45 (all lymhocytes except macrophages)</li>
                <li>S100B+PMEL17 (melanocytes)</li>
                <li>
                  DNA (non-tumor/ non-lmphocyte cells) - remaining material from
                  patient
                </li>
              </ol>
            </div>
            <div sx={{ img: { width: '50%' } }}>
              <ZoomableImage preview={img2} src={img2} />
            </div>
          </div>
        </motion.div>
      )}
    </Context.Consumer>
  )
}

export const Page2: React.FC = (): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          {...fadeAnimations}
        >
          <div sx={{ paddingLeft: '3rem' }}>
            <h3 sx={{ color: 'primary' }}>
              Your <span className="highlighted">RNA</span> Case Study Endpoint:{' '}
              <span className="highlighted">Predictive Biomarkers</span>
            </h3>
            <br />
            <h4 sx={{ color: 'black' }}>
              Differential Expression is Highest Between Tumor and Stroma
            </h4>
            <br />
            <br />
            <ul
              sx={{
                lineHeight: '2rem',
                fontSize: '1rem',
              }}
            >
              <li>
                <span sx={{ color: 'secondary' }}>•</span> Largest fold changes
                differences observed between tumor and stromal compartment
              </li>
              <li>
                <span sx={{ color: 'secondary' }}>•</span> Lineage associated
                markers were most differential between CD68 and CD45
                compartments
              </li>
            </ul>
          </div>

          <div
            sx={{
              img: { width: '45%' },
            }}
          >
            <ZoomableImage preview={img3} src={img3} />
          </div>
        </motion.div>
      )}
    </Context.Consumer>
  )
}

export const Page3: React.FC = (): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          {...fadeAnimations}
        >
          <div sx={{ paddingLeft: '3rem' }}>
            <h3 sx={{ color: 'primary' }}>
              Your <span className="highlighted">RNA</span> Case Study Endpoint:{' '}
              <span className="highlighted">Predictive Biomarkers</span>
            </h3>
            <br />
            <h4 sx={{ color: 'black' }}>
              Tumor Compartments Cluster Separately Under Unsupervised Analysis
            </h4>
            <br />
            <br />
            <ul
              sx={{
                lineHeight: '2rem',
                fontSize: '1em',
              }}
            >
              <li>
                <span sx={{ color: 'secondary' }}>•</span> Analyzing all
                compartments together clusters are driven by tumor vs stroma
              </li>
              <li>
                <span sx={{ color: 'secondary' }}>•</span> 'Hot' CD68 and CD45
                compartments with higher immune maker expression tend to be from
                patients with CR/PR
              </li>
            </ul>
          </div>

          <div
            sx={{
              img: { width: '45%' },
            }}
          >
            <ZoomableImage preview={img4} src={img4} />
          </div>
        </motion.div>
      )}
    </Context.Consumer>
  )
}

export const Page4: React.FC = (): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          {...fadeAnimations}
        >
          <div sx={{ paddingLeft: '3rem' }}>
            <h3 sx={{ color: 'primary' }}>
              Your <span className="highlighted">RNA</span> Case Study Endpoint:{' '}
              <span className="highlighted">Predictive Biomarkers</span>
            </h3>
            <br />
            <h4 sx={{ color: 'black' }}>
              Patient Outcome Associated with Compartment Specific Expression
              Profile
            </h4>
            <br />
            <br />
            <p sx={{ fontSize: '1em', fontFamily: 'GothamBook' }}>
              Several probes, primarily in the stromal compartments, are
              associated with patient outcome
            </p>
            <br />
            <br />
            <ul
              sx={{
                lineHeight: '2em',
                fontSize: '1rem',
                paddingLeft: '2em',
                paddingBottom: '1rem',
              }}
            >
              <li>
                <span sx={{ color: 'secondary' }}>•</span> PDL1 showed strongest
                association with OS in the CD68 compartment
              </li>
              <li>
                <span sx={{ color: 'secondary' }}>•</span> B2M in CD45
                compartment was associated with both PFS and OS as well
              </li>
              <li>
                <span sx={{ color: 'secondary' }}>•</span> Results are
                independent of prior treatment
              </li>
            </ul>
          </div>

          <div
            sx={{
              img: { width: '50%' },
            }}
          >
            <ZoomableImage preview={img5} src={img5} />
          </div>
        </motion.div>
      )}
    </Context.Consumer>
  )
}

interface CaseStudyProps {
  children?: React.ReactNode
  className?: string
  readMore?: Function
  nextPage?: Function
  previousPage?: Function
  index?: number
  pages?: React.FC[]
}

const CaseStudy: React.FC<CaseStudyProps> = ({
  className,
  readMore,
  index = -1,
  nextPage,
  previousPage,
  pages = [Page1, Page2, Page3, Page4],
}): React.ReactElement => {
  const gotoNextPage = () => (index >= pages.length - 1 ? 0 : index + 1)
  const gotoPrevPage = () => (index <= 0 ? index - 1 : pages.length - 1)
  const Page = index < 0 ? FrontPage : pages[index]
  return (
    <motion.div
      {...fadeAnimations}
      className={className}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AnimatePresence>
        {
          <Page
            key={index}
            readMore={(): React.FC<MediaPack>[] => readMore(pages)}
          />
        }
      </AnimatePresence>
    </motion.div>
  )
}

export default CaseStudy
