import { StepNode, NodeTypes } from './types'
import { AnalyteIds } from './analytes'
import { SampleIds } from './samples'
import { RegionIds } from './regions'
import { AssayIds } from './assays'
import lesinskiMedia from './media/case-studies/lesinski-case-study.png'

import CRCGeometric from './media/case-studies/CRCGeometric'
import CRCSegment from './media/case-studies/CRCSegment'
import CRCContour from './media/case-studies/CRCContour'
import Lesinski from './media/case-studies/Lesinski'
import Rimm from './media/case-studies/Rimm'

// import crcMedia from './media/case-studies/crc-casestudy.png'

export enum CaseStudyIds {
  LESINSKI = 'lesinski-case-study',
  RIMM = 'rimm-case-study',
  CRC_GEO = 'crc-geometric-case-study',
  CRC_CONTOUR = 'crc-contour-case-study',
  CRC_SEGMENT = 'crc-segment-case-study',
}

export const LesinskiCaseStudy: StepNode = {
  id: CaseStudyIds.LESINSKI,
  type: NodeTypes.CASE_STUDY,
  label: 'Lesinski Case Study',
  rules: [[AnalyteIds.PROTEIN, SampleIds.SMT, RegionIds.GEO]],
  media: Lesinski,
}
export const RimmCaseStudy: StepNode = {
  id: CaseStudyIds.RIMM,
  type: NodeTypes.CASE_STUDY,
  label: 'Rimm Case Study',
  rules: [
    [AnalyteIds.PROTEIN, SampleIds.SMT, RegionIds.RARE],
    [AnalyteIds.PROTEIN, SampleIds.TMA, RegionIds.RARE],
  ],
  media: Rimm,
}
export const CRCGeometricCaseStudy: StepNode = {
  id: CaseStudyIds.CRC_GEO,
  type: NodeTypes.CASE_STUDY,
  label: 'CRC Geometric Case Study',
  rules: [[AnalyteIds.RNA, SampleIds.SMT, RegionIds.GEO]],
  media: CRCGeometric,
}
export const CRCContourCaseStudy: StepNode = {
  id: CaseStudyIds.CRC_CONTOUR,
  type: NodeTypes.CASE_STUDY,
  label: 'CRC Contour Case Study',
  rules: [[AnalyteIds.RNA, SampleIds.SMT, RegionIds.CONTOUR]],
  media: CRCContour,
}
export const CRCSegmentCaseStudy: StepNode = {
  id: CaseStudyIds.CRC_SEGMENT,
  type: NodeTypes.CASE_STUDY,
  label: 'CRC Segment Case Study',
  rules: [[AnalyteIds.RNA, SampleIds.SMT, RegionIds.SEGMENT]],
  media: CRCSegment,
}

export const map: Map<string, StepNode> = new Map([
  [CaseStudyIds.LESINSKI, LesinskiCaseStudy],
  [CaseStudyIds.RIMM, RimmCaseStudy],
  [CaseStudyIds.CRC_GEO, CRCGeometricCaseStudy],
  [CaseStudyIds.CRC_CONTOUR, CRCContourCaseStudy],
  [CaseStudyIds.CRC_SEGMENT, CRCSegmentCaseStudy],
])
