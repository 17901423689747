// Example for using discriminated union types.
export interface Page {
  path: string
  label: string
  heading: string
  subhead: string
  gotoFunction?: Function
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface PageState {
  readonly pages: Page[]
  readonly pageIndex: number
  readonly previousPage: Page
  readonly nextPage: Page
  readonly profileIndex: number
  readonly exploreIndex: number
}

// export interface GlobalPageState {
//   profileIndex: number
//   exploreIndex: number
//   // analyzeIndex: number
//   // demoIndex: number
// }

// Use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export enum PageActionTypes {
  SET_PAGE_INDEX = '@@pages/SET_PAGE_INDEX',
  NEXT_PAGE = '@@pages/NEXT_PAGE',
  PREVIOUS_PAGE = '@@pages/PREVIOUS_PAGE',
  SET_PAGES = '@@pages/SET_PAGES',
  DEFINE_NEXT_PAGE = '@@pages/DEFINE_NEXT_PAGE',
  DEFINE_PREVIOUS_PAGE = '@@pages/DEFINE_PREVIOUS_PAGE',
  SET_PROFILE_INDEX = '@@pages/SET_PROFILE_INDEX',
  SET_EXPLORE_INDEX = '@@pages/SET_EXPLORE_INDEX',
}
