/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React, { useEffect, useContext, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
import workflowPortrait from './workflows.svg'
import workflowLandscape from './workflows-landscape.svg'
import { StepNodeComponent } from '../../types'
import { Context } from 'components/Layout'

const WorkflowAnimation: React.FC<StepNodeComponent & { analyte: string }> = ({
  children,
  className,
  delay = 1,
  analyte,
}): React.ReactElement => {
  const [frame, setFrame] = useState(0)
  const layers = [5, 4, 3, 2, 1, 0]
  const nextFrame = () =>
    setFrame(frame < layers.length - 1 ? frame + 1 : frame)

  const context = useContext(Context)
  const graylines = useAnimation()
  const labels = useAnimation()
  const steps = useAnimation()
  const greenlines = useAnimation()
  const greenlabels = useAnimation()
  const glows = useAnimation()

  const themeUI = useThemeUI()
  const { theme } = themeUI
  const { isLandscape } = context
  const workflow = isLandscape ? workflowLandscape : workflowPortrait

  const animations = async () => {
    labels.start(i => ({
      opacity: 1,
      transition: {
        delay: 0.1 * i,
        duration: 0.5,
      },
    }))
    graylines.start(i => ({
      opacity: 1,
      transition: {
        delay: 1 + 0.2 * i,
        duration: 0.5,
      },
    }))
    steps.start(i => ({
      opacity: 1,
      transition: {
        delay: 1 + 0.2 * i,
        duration: 0.5,
      },
    }))
    greenlabels.start(i => ({
      opacity: [0, 1, 1, 1],
      transition: {
        delay: 4 + 4 * i,
        duration: 1.5,
      },
    }))
    greenlines.start(i => ({
      opacity: [0, 1, 1, 1],
      transition: {
        delay: 3 + 4 * i,
        duration: 1.5,
      },
    }))

    glows.start(i => ({
      opacity: [0, 1, 1, 1, 1],
      transition: {
        delay: 4 + 4 * i,
        duration: 1.5,
      },
    }))
  }
  useEffect(() => {
    animations()
  }, [])

  return (
    <motion.svg
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      sx={{ width: '100%', height: '100%' }}
      opacity={1}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={isLandscape ? '0 0 3048 768' : '0 0 1216 2118.2'}
      className={className}
    >
      {layers.map(i => {
        return (
          <React.Fragment key={`glows-${i}`}>
            <motion.use
              custom={i}
              initial={{ opacity: 0 }}
              animate={glows}
              href={`${workflow}#step${i}glow`}
            />
 
          </React.Fragment>
        )
      })}
      {layers.map(i => {
        return (
          <React.Fragment key={`layers-${i}`}>
            <motion.use
              custom={i}
              initial={{ opacity: 0 }}
              animate={graylines}
              href={`${workflow}#step${i}grayline`}
            />
          </React.Fragment>
        )
      })}

      {layers.map(i => {
        return (
          <React.Fragment key={`layers-${i}`}>
            <motion.use
              custom={i}
              initial={{ opacity: 0 }}
              animate={greenlines}
              href={`${workflow}#step${i}line`}
            />
            <motion.use
              custom={i}
              initial={{ opacity: 0 }}
              animate={steps}
              href={`${workflow}#step${i}${i === 0 || i === 2 ? analyte : '' }`}
            />
            <motion.use
              custom={i}
              initial={{ opacity: 0 }}
              animate={labels}
              href={`${workflow}#step${i}label`}
            />
            <motion.use
              custom={i}
              initial={{ opacity: 0 }}
              animate={greenlabels}
              href={`${workflow}#step${i}greenlabel`}
            />
          </React.Fragment>
        )
      })}

      <motion.use
        custom={0}
        // sx={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        animate={labels}
        transition={{ duration: 1, delay: 1 }}
        href={`${workflow}#${analyte}-label`}
      />
    </motion.svg>
  )
}

export default WorkflowAnimation
