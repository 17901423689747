import { combineReducers, Reducer } from 'redux'
import { LayoutState, layoutReducer } from './layout'
import { State as ProfileState, profileReducer } from './profile'
import { PageState, pagesReducer } from './pages'
// import { all, fork } from 'redux-saga/effects'

export interface ApplicationState {
  layout: LayoutState
  profile: ProfileState
  pages: PageState
}

export const createRootReducer = (): Reducer<ApplicationState> =>
  combineReducers({
    layout: layoutReducer,
    profile: profileReducer,
    pages: pagesReducer,
  })

// export function* rootSaga() {
//   yield all([fork(heroesSaga), fork(teamsSaga)])
// }
