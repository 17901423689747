import { Reducer } from 'redux'
import { State, ActionTypes } from './types'
import { steps, nodes } from 'content/profile'
import { StepNode, Step } from 'content/profile/types'
import { xor, difference } from 'lodash'

export const initialState: State = {
  stepIndex: 0,
  maxIndex: 0,
  steps,
  selections: [],
  sample: null,
  analyte: null,
  region: null,
  workflow: null,
  assay: null,
  caseStudy: null,
}

const getAvailableOptions = (
  selections: string[],
  options: StepNode[],
  stepIndex: number,
  currentStepIndex: number
): string[] => {
  const visibleOptions = options.map(option => option.id)
  // console.log(currentIndex, stepIndex, visibleOptions)
  // console.log(visibleOptions)
  // if (currentStepIndex >= stepIndex) return visibleOptions
  const availableOptions: string[] = []
  for (const option of options) {
    let enabled = false
    const { rules } = option

    // for (const selection of selections) {
    for (const rulePath of rules) {
      const formattedRules = [...rulePath]
      for (let i = 0; i < selections.length; i++) {
        if (!formattedRules[i]) formattedRules.push(selections[i])
      }
      if (difference(formattedRules, selections).length === 0) enabled = true
    }
    // }
    if (enabled) availableOptions.push(option.id)
  }
  console.log(availableOptions)
  return availableOptions
}

const reducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_STEP_INDEX: {
      const totalSteps = state.steps.length - 1
      let stepIndex = action.payload
      if (stepIndex > totalSteps) stepIndex = totalSteps
      if (stepIndex < 0) stepIndex = 0
      return { ...state, stepIndex }
    }
    case ActionTypes.NEXT_STEP: {
      let nextStep = state.stepIndex + 1
      if (nextStep > state.maxIndex) nextStep = state.maxIndex
      return { ...state, stepIndex: nextStep }
    }
    case ActionTypes.PREVIOUS_STEP: {
      let previousStep = state.stepIndex - 1
      if (previousStep < 0) previousStep = 0
      return { ...state, stepIndex: previousStep }
    }
    case ActionTypes.SET_STEP_SELECTION: {
      const { index: stepIndex, id } = action.payload
      const { selections, steps } = state
      const newSelections =
        stepIndex >= selections.length - 1 ? [...selections] : []
      for (let i = 0; i < stepIndex; i++) {
        newSelections[i] = selections[i]
      }
      newSelections[stepIndex] = id
      const newSteps: Step[] = []
      const stepSelections: Record<string, string | null> = {}
      console.log('Step Selections: ', newSelections)
      for (let i = 0; i < steps.length; i++) {
        const step = { ...steps[i] }
        const node = i < stepIndex + 1 ? newSelections[i] : null

        step.availableOptions = getAvailableOptions(
          newSelections,
          step.options,
          i,
          stepIndex
        )
        newSteps.push(step)
        stepSelections[step.type] = node
      }

      const totalSteps = state.steps.length - 1
      const maxIndex =
        newSelections.length < totalSteps ? newSelections.length : totalSteps
      return {
        ...state,
        selections: newSelections,
        ...stepSelections,
        steps: newSteps,
        maxIndex,
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as profileReducer }
