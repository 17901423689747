/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion } from 'framer-motion'
import NavArrowGfx from 'components/ui/icons/inline/arrow.svg'

interface Props {
  className?: string
  onClick?: () => void
  pointLeft?: boolean
}

const GalleryArrowBtn: React.FC<Props> = ({
  onClick,
  className,
  pointLeft,
}): React.ReactElement => {
  return (
    <motion.button
      className={className}
      sx={{
        borderWidth: '.1rem',
        borderRadius: '.3rem',
        borderStyle: 'solid',
        borderColor: 'muted',
        width: '1.8rem',
        height: '1.8rem',
        m: '.2rem',
      }}
      onClick={onClick}
    >
      <NavArrowGfx
        sx={{
          width: '1.8rem',
          height: '1.8rem',
          maxWidth: '100%',
          maxHeight: '100%',
          transform: pointLeft && 'scaleX(-1)',
          p: '.2rem',
        }}
      />
    </motion.button>
  )
}

export default GalleryArrowBtn
