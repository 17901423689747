/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import ZoomableImage from 'components/ZoomableImage'

import img1 from './crc-geometric-detail1.jpg'
import img2 from './crc-geometric-detail2.jpg'
import img3 from './crc-geometric-detail3full.jpg'
import fadeAnimations from 'animations/fade'
import ReadMoreLink from 'components/CaseStudyDetails/ReadMoreLink'
import { MediaPack } from 'content/profile/types'
import { Context } from 'components/Layout'

const FrontPage: React.FC<MediaPack> = ({
  readMore,
  index,
}): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            textAlign: 'center',
            h5: { p: '1rem' },
          }}
          {...fadeAnimations}
        >
          <h5>Immune Biology of Colorectal Tumors</h5>
          <div
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'row',
            }}
          >
            <div
              sx={{
                textAlign: 'center',
                fontSize: '.7rem',
                mr: '2rem',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: context.isLandscape ? 'center' : '',
              }}
            >
              <ZoomableImage
                preview={img1}
                src={img1}
                // sx={{ maxWidth: context.isLandscape ? '50%' : '85%' }}
              />
              <span sx={{ py: '.8rem' }}>colorectal tumors (CRC)</span>
            </div>
            <div
              sx={{
                color: 'primary',
                fontSize: '1rem',
                textAlign: 'left',
                width: '50%',
              }}
            >
              <p>
                DSP characterizes microsatellite stable (MSS) or instable (MSI)
                colorectal tumors to differentiate immune hot and cold regions
                of the tumors despite MSS status using the the tumor
                inflammation signature (TIS) gene set.
              </p>
              {/* This link will be displayed if the 'readMore' function is passed.
             It is how you open up the pages defined below */}
              {readMore && <ReadMoreLink readMore={readMore} />}
            </div>
          </div>
        </motion.div>
      )}
    </Context.Consumer>
  )
}

export const Page1: React.FC = (): React.ReactElement => {
  return (
    <motion.div
      sx={{ display: 'flex', flexDirection: 'column' }}
      {...fadeAnimations}
    >
      <h3 sx={{ color: 'primary', textAlign: 'center' }}>
        Your <span className="highlighted">RNA</span> Case Study Endpoint:{' '}
        <span className="highlighted">CRC Geometric</span>
      </h3>
      <br />
      <h5 sx={{ textAlign: 'center', fontSize: '1rem' }}>
        Various regions types were chosen throughout the CRC sample
      </h5>
      <ZoomableImage preview={img2} src={img2} />
      <div>
        <p sx={{ padding: '1rem' }}>
          Each sample was profiled with 400 um diameter circular ROIs in
          CD45-enriched locations: 5 in the invasive margin (IM) and 4 in the
          tumor center (CT) as well as 3 in pan-cytokeratin enriched (Tumor)
          locations.
        </p>
      </div>
    </motion.div>
  )
}

export const Page2: React.FC = (): React.ReactElement => {
  return (
    <motion.div
      sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
      {...fadeAnimations}
    >
      <h3 sx={{ color: 'primary', textAlign: 'center' }}>
        Your <span className="highlighted">RNA</span> Case Study Endpoint:{' '}
        <span className="highlighted">CRC Geometric</span>
      </h3>
      <br />
      <h5 sx={{ textAlign: 'center', fontSize: '1rem' }}>
        Unique expression patterns of immune markers by location within the TME
      </h5>
      <br />
      <h3 sx={{ textAlign: 'center' }}>RNA Immune Pathways</h3>
      <div
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          img: { width: '35%' },
        }}
      >
        <ZoomableImage preview={img3} src={img3} />
      </div>
    </motion.div>
  )
}

interface CaseStudyProps {
  children?: React.ReactNode
  className?: string
  readMore?: Function
  nextPage?: Function
  previousPage?: Function
  index?: number
  pages?: React.FC[]
}

const CaseStudy: React.FC<CaseStudyProps> = ({
  className,
  readMore,
  index = -1,
  nextPage,
  previousPage,
  pages = [Page1, Page2],
}): React.ReactElement => {
  const gotoNextPage = () => (index >= pages.length - 1 ? 0 : index + 1)
  const gotoPrevPage = () => (index <= 0 ? index - 1 : pages.length - 1)
  const Page = index < 0 ? FrontPage : pages[index]
  return (
    <motion.div
      {...fadeAnimations}
      className={className}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AnimatePresence>
        {<Page key={index} readMore={() => readMore(pages)} />}
      </AnimatePresence>
    </motion.div>
  )
}

export default CaseStudy
