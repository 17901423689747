import { action } from 'typesafe-actions'

import { PageActionTypes, Page } from './types'

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.

export const setPageIndex = (index: number) =>
  action(PageActionTypes.SET_PAGE_INDEX, index)

export const nextPageIndex = () => action(PageActionTypes.NEXT_PAGE)

export const previousPageIndex = () => action(PageActionTypes.PREVIOUS_PAGE)

export const setPages = (pages: Page[]) =>
  action(PageActionTypes.SET_PAGES, pages)

export const defineNextPage = (page: Page) =>
  action(PageActionTypes.DEFINE_NEXT_PAGE, page)

export const definePreviousPage = (page: Page) =>
  action(PageActionTypes.DEFINE_PREVIOUS_PAGE, page)

export const setProfileIndex = (index: number) =>
  action(PageActionTypes.SET_PROFILE_INDEX, index)

export const setExploreIndex = (index: number) =>
  action(PageActionTypes.SET_EXPLORE_INDEX, index)
