/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import ArrowGfx from 'components/ui/icons/inline/arrow.inline.svg'
import { Context } from 'components/Layout'

interface Props {
  children?: React.ReactNode
  className?: string
  onClick?: any
  pointLeft?: boolean
}

const SubPageArrowBtn: React.FC<Props> = ({
  children,
  pointLeft,
  className,
  onClick,
}): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <React.Fragment>
          <motion.button
            onClick={onClick}
            className={className}
            sx={{
              position: 'relative',
              border: 'none',
              color: 'primary',
              fontWeight: '500',
              fontSize: ['.5rem', '.8rem'],
              backgroundColor: '#fff',
              margin: 0,
              padding: 0,
              ':focus': { outline: 0 },
              cursor: 'pointer',
              px: '2rem',
              pt: '2rem',
              pb: '2rem',
              width: '2rem',
              borderRadius: '.5rem',
              minWidth: ['5rem'],
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              transition: 'all ease .3s',
              '&:hover': {
                background:
                  'linear-gradient(0deg, rgba(25, 73, 131, 0.1), rgba(25, 73, 131, 0.1)), primary',
                borderColor: '#e6edf3',
                color: '#fff',
                backgroundColor: 'primary',
              },
              '&:disabled': {
                backgroundColor: 'muted',
                borderColor: 'muted',
              },
              '&:hover path': {
                fill: '#fff',
              },
            }}
            initial={{
              left: pointLeft && `-5rem`,
              right: !pointLeft && `-5rem`,
            }}
            animate={{
              left: pointLeft && '-0.3rem',
              right: !pointLeft && '-0.3rem',
            }}
            exit={{
              left: pointLeft && `-5rem`,
              right: !pointLeft && `-5rem`,
              transition: { duration: 0.3 },
            }}
            transition={{ duration: 1, delay: 1 }}
          >
            <ArrowGfx
              sx={{
                width: ['2rem', '3rem'],
                pb: '2rem',
                transform: pointLeft && 'scaleX(-1)',
                path: {
                  fill: 'primary',
                  '&:hover': { fill: '#fff' },
                },
              }}
            />
            {children}
          </motion.button>
        </React.Fragment>
      )}
    </Context.Consumer>
  )
}

export default SubPageArrowBtn
