/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import ZoomableImage from 'components/ZoomableImage'

import img1 from './crc-segment-detail1.jpg'

import fadeAnimations from 'animations/fade'
import ReadMoreLink from 'components/CaseStudyDetails/ReadMoreLink'
import { MediaPack } from 'content/profile/types'

import { Context } from 'components/Layout'

const FrontPage: React.FC<MediaPack> = ({
  readMore,
  index,
}): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            h5: { p: '1rem' },
          }}
          {...fadeAnimations}
        >
          <h5>
            Explore the Tumor-Microenvironment with the Cancer Transcriptome
            Atlas
          </h5>
          <div>
            <div
              sx={{
                textAlign: 'center',
                fontSize: '.7rem',
                mr: '2rem',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ZoomableImage
                src={img1}
                preview={img1}
                sx={{ width: context.isLandscape ? '100%' : '100%' }}
              />

            </div>
            <div
              sx={{
                color: 'primary',
                fontSize: '1rem',
                textAlign: 'left',
                width: '100%',
              }}
            ></div>
          </div>
        </motion.div>
      )}
    </Context.Consumer>
  )
}

interface CaseStudyProps {
  children?: React.ReactNode
  className?: string
  readMore?: Function
  nextPage?: Function
  previousPage?: Function
  index?: number
  pages?: React.FC[]
}

const CaseStudy: React.FC<CaseStudyProps> = ({
  className,
  readMore,
  index = -1,
  nextPage,
  previousPage,
  // pages = [Page1, Page2],
}): React.ReactElement => {
  const gotoNextPage = () => (index >= pages.length - 1 ? 0 : index + 1)
  const gotoPrevPage = () => (index <= 0 ? index - 1 : pages.length - 1)
  const Page = index < 0 ? FrontPage : pages[index]
  return (
    <motion.div
      {...fadeAnimations}
      className={className}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AnimatePresence>
        {
          <Page
            key={index}
            readMore={(): React.FC<MediaPack>[] => readMore(pages)}
          />
        }
      </AnimatePresence>
    </motion.div>
  )
}

export default CaseStudy
