import { Reducer } from 'redux'
import { PageState, PageActionTypes } from './types'
import { PageIds, map } from 'content/pages/root'

export const initialState: PageState = {
  pages: [
    map.get(PageIds.INDEX),
    map.get(PageIds.PROFILE),
    map.get(PageIds.EXPLORE),
    map.get(PageIds.ANALYZE),
    map.get(PageIds.DEMO),
  ],
  pageIndex: 0,
  previousPage: null,
  nextPage: null,
  exploreIndex: 0,
  profileIndex: 0,
}

export const reducer: Reducer<PageState> = (state = initialState, action) => {
  switch (action.type) {
    case PageActionTypes.SET_PAGE_INDEX: {
      const totalPages = state.pages.length - 1
      let pageIndex = action.payload
      if (pageIndex > totalPages) pageIndex = totalPages
      if (pageIndex < 0) pageIndex = 0
      return { ...state, pageIndex }
    }
    case PageActionTypes.NEXT_PAGE: {
      let nextPage = state.pageIndex + 1
      const totalPages = state.pages.length - 1
      if (nextPage > totalPages) nextPage = totalPages
      return { ...state, pageIndex: nextPage }
    }
    case PageActionTypes.PREVIOUS_PAGE: {
      let previousPage = state.pageIndex - 1
      if (previousPage < 0) previousPage = 0
      return { ...state, pageIndex: previousPage }
    }
    case PageActionTypes.SET_PAGES: {
      const { pages } = action
      return { ...state, pages, pageIndex: 0 }
    }
    case PageActionTypes.DEFINE_NEXT_PAGE: {
      const nextPage = action.payload
      // console.log('DEFINE NEXT PAGE', nextPage)
      return { ...state, nextPage }
    }
    case PageActionTypes.DEFINE_PREVIOUS_PAGE: {
      const previousPage = action.payload
      // console.log('DEFINE PREVIOUS PAGE', previousPage)
      return { ...state, previousPage }
    }
    case PageActionTypes.SET_EXPLORE_INDEX: {
      const index = action.payload
      return { ...state, exploreIndex: index }
    }
    case PageActionTypes.SET_PROFILE_INDEX: {
      const index = action.payload
      return { ...state, profileIndex: index }
    }
    default: {
      return state
    }
  }
}

export { reducer as pagesReducer }
