import React, { useContext } from 'react'
import Link, { GatsbyLinkProps } from 'gatsby-link'
import styled from '@emotion/styled'
import { Context } from 'components/APIProvider'

interface Props<TState> extends GatsbyLinkProps<TState> {
  children?: React.ReactNode
  target?: string
  href?: string
}

interface LinkProps {
  to?: string
  href?: string
  target?: string
}

const GatsbyLink: React.FC<Props<object>> = ({
  children,
  to,
  target = '_self',
  href,
  ...passThroughProps
}): React.ReactElement => {
  if (href) throw new Error(`'href' passed in, use 'to' instead.`)
  const { userInteract } = useContext(Context)
  let LinkComponent: React.ReactType = Link
  let linkProps: LinkProps = { to }
  if (to.indexOf('http') !== -1) {
    LinkComponent = styled.a``
    linkProps = { href: to, target }
  }
  return (
    <LinkComponent
      {...passThroughProps}
      {...linkProps}
      onClick={() => userInteract(to, 'LinkButton', '')}
    >
      {children}
    </LinkComponent>
  )
}

export default GatsbyLink
