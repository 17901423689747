/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import fadeAnimations from 'animations/fade'

const ReadMoreLink: React.FC<{ readMore: () => void }> = ({
  readMore,
}): React.ReactElement => {
  return (
    <AnimatePresence>
      {/* {readMore && ( */}
      <motion.a
        onClick={readMore}
        sx={{
          color: 'anchor',
          '&:hover': { color: 'hover', fontWeight: 'bold' },
        }}
        {...fadeAnimations}
      >{`Read more >`}</motion.a>
      {/* )} */}
    </AnimatePresence>
  )
}

export default ReadMoreLink
