import { StepNode, NodeTypes } from './types'
import { AnalyteIds } from './analytes'
import { SampleIds } from './samples'
import { RegionIds } from './regions'

import humanio from './media/assays/humanio.png'
import cta from './media/assays/cta.png'
import immunePathways from './media/assays/ImmunePathways.png'

export enum AssayIds {
  IMMUNE = 'immune-pathways',
  CTA = 'cta',
  HUMAN_IO = 'human-io',
}

export const ImmunePathways: StepNode = {
  id: AssayIds.IMMUNE,
  type: NodeTypes.ASSAY,
  label: 'Immune Pathways',
  rules: [[AnalyteIds.RNA]],
  media: immunePathways,
}
export const CTA: StepNode = {
  id: AssayIds.CTA,
  type: NodeTypes.ASSAY,
  label: 'CTA',
  rules: [[AnalyteIds.RNA]],
  media: cta,
}
export const HumanIO: StepNode = {
  id: AssayIds.HUMAN_IO,
  type: NodeTypes.ASSAY,
  label: 'Human IO',
  rules: [[AnalyteIds.PROTEIN]],
  media: humanio,
}

export const map: Map<string, StepNode> = new Map([
  [AssayIds.HUMAN_IO, HumanIO],
  [AssayIds.CTA, CTA],
  [AssayIds.IMMUNE, ImmunePathways],
])
