/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import ZoomableImage from 'components/ZoomableImage'

import img1 from './crc-contour-detail1.jpg'
import img2 from './crc-geometric-detail2.jpg'
import img3 from './crc-geometric-detail3.jpg'
import fadeAnimations from 'animations/fade'
import ReadMoreLink from 'components/CaseStudyDetails/ReadMoreLink'
import { MediaPack } from 'content/profile/types'

import { Context } from 'components/Layout'

const FrontPage: React.FC<MediaPack> = ({
  readMore,
  index,
}): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            h5: { p: '1rem' },
          }}
          {...fadeAnimations}
        >
          <h5>
            Explore pathway signaling across complex tissue structures and tumor
            boundaries
          </h5>
          <div sx={{ display: 'flex', flexDirection: 'column' }}>
            <div
              sx={{
                textAlign: 'center',
                fontSize: '.7rem',
                mr: '2rem',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ZoomableImage
                preview={img1}
                src={img1}
                sx={{ width: context.isLandscape ? '75%' : '100%' }}
              />
            </div>
          </div>
          <br />
          <p sx={{ color: 'primary' }}>
            The Cancer Transcriptome Atlas with 1800+ genes was used to explore
            every contour along the tumor-immune boundary.
          </p>
        </motion.div>
      )}
    </Context.Consumer>
  )
}

const Page1: React.FC = (): React.ReactElement => {
  return (
    <motion.div
      sx={{ display: 'flex', flexDirection: 'column' }}
      {...fadeAnimations}
    >
      <h5>Various regions types were chosen throughout the CRC sample</h5>
      <ZoomableImage preview={img2} src={img2} />
      <div>
        <p>
          Each sample was profiled with 400 um diameter circular ROIs in
          CD45-enriched locations: 5 in the invasive margin (IM) and 4 in the
          tumor center (CT) as well as 3 in pan-cytokeratin enriched (Tumor)
          locations.
        </p>
      </div>
    </motion.div>
  )
}

const Page2: React.FC = (): React.ReactElement => {
  return (
    <motion.div
      sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}
      {...fadeAnimations}
    >
      <h5>
        Unique expression patterns of immune markers by location within the TME
      </h5>

      <div
        sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}
      >
        <ZoomableImage preview={img3} src={img3} />
      </div>
    </motion.div>
  )
}

interface CaseStudyProps {
  children?: React.ReactNode
  className?: string
  readMore?: Function
  nextPage?: Function
  previousPage?: Function
  index?: number
  pages?: React.FC[]
}

const CaseStudy: React.FC<CaseStudyProps> = ({
  className,
  readMore,
  index = -1,
  nextPage,
  previousPage,
  pages = [Page1, Page2],
}): React.ReactElement => {
  const gotoNextPage = () => (index >= pages.length - 1 ? 0 : index + 1)
  const gotoPrevPage = () => (index <= 0 ? index - 1 : pages.length - 1)
  const Page = index < 0 ? FrontPage : pages[index]
  return (
    <motion.div
      {...fadeAnimations}
      className={className}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AnimatePresence>{<Page key={index} />}</AnimatePresence>
    </motion.div>
  )
}

export default CaseStudy
