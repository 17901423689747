/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, {
  useReducer,
  useLayoutEffect,
  useEffect,
  useState,
  createContext,
  Dispatch,
  useContext,
} from 'react'
import { Action, action } from 'typesafe-actions'
import { AnyAction } from 'redux'
// import { getSearchParams } from 'gatsby-query-params'
import { Context as LayoutContext } from 'components/Layout'
import queryString from 'query-string'
import { camelCase } from 'change-case'

interface Props {
  children?: React.ReactNode
}

interface ApiContext {
  attendeeID: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitDemoForm: (data: any) => any
  enterPage: (pageId: string, value: string) => void
  userInteract: (pageId: string, buttonId: string, value: string) => void
}

export interface ProfileState {
  readonly userInteractions: ActionProps[]
  readonly userPageViews: ActionProps[]
  readonly userDwellTimes: ActionProps[]
  readonly attendeeID?: string | null
  readonly city?: string | null
  readonly country?: string | null
  readonly countryCode?: string | null
  readonly degree?: string | null
  readonly email?: string | null
  readonly firstName?: string | null
  readonly lastName?: string | null
  readonly institution?: string | null
  readonly institutionType?: string | null
  readonly jobTitle?: string | null
  readonly kioskAction?: string[] | null
  readonly language?: string | null
  readonly profileResult?: string[] | null
  readonly scheduledDemo?: string[] | null
  readonly stateProvinceRegion?: string | null
  readonly streetAddress?: string | null
  readonly telephone?: string | null
  readonly utcAdded?: Date | null
  readonly utcAddedBack?: Date | null
  readonly utcLastUpdated?: Date | null
  readonly utcLastUpdatedBack?: Date | null
  readonly zip?: string | null
}

export interface ApiState extends ProfileState {
  readonly clientId: string | null
  readonly clientSecret: string | null
  readonly token: string | null
}

export interface ActionProps {
  attendeeID: string
  userAction: string
  currentPage: string
  value: string
  UtcAdded: string
}

// const userEmail = 'test@yipkos.com'
const apiRoot = 'https://api.nanostring.yipkos.com/'
const getToken = `${apiRoot}oauth/token`
// const getAttendeeId = `${apiRoot}/api/attendee/${userEmail}`
const scheduleDemo = `${apiRoot}api/attendee/schedule-demo`
const trackingApi = `${apiRoot}api/attendee/kiosk-action`

const clientId = '7a36d777-6c50-4f00-982d-94a9a6bbf717'
const clientSecret = '227d0e79-baec-4e3c-b7af-6635aa55a8b6'
const grantType = 'password'
// const tempToken =
// 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJzb21lX2lkIiwibmJmIjoxNTg5NTE1MzY0LCJleHAiOjE1OTA4MTEzNjQsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6NTYyNzUiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjU2Mjc1In0.9lco6fRyyd7f-alrIqHqiLL7w8dQRkoI4A-mfFqVQD4'
// const attendeeID = 17

export const Context = createContext({
  submitDemoForm: null,
  enterPage: null,
  userInteract: null,
  state: null,
})

const initialState: ApiState = {
  userInteractions: [],
  userPageViews: [],
  userDwellTimes: [],
  clientId: null,
  clientSecret: null,
  token: null,
  attendeeID: null,
  city: null,
  country: null,
  countryCode: null,
  degree: null,
  email: null,
  firstName: null,
  lastName: null,
  institution: null,
  institutionType: null,
  jobTitle: null,
  kioskAction: null,
  language: null,
  profileResult: null,
  scheduledDemo: null,
  stateProvinceRegion: null,
  streetAddress: null,
  telephone: null,
  utcAdded: null,
  utcAddedBack: null,
  utcLastUpdated: null,
  utcLastUpdatedBack: null,
  zip: null,
}

export enum ApiActionTypes {
  RECEIVE_AUTH = '@@api/RECEIVE_AUTH',
  RECEIVE_PROFILE = '@@api/RECEIVE_PROFILE',
  ENTER_PAGE = '@@api/ENTER_PAGE',
  USER_INTERACT = '@@api/USER_INTERACT',
}

export const receiveAuth = (token: string): AnyAction => {
  return action(ApiActionTypes.RECEIVE_AUTH, token)
}

export const receiveProfile = (profile: ProfileState): AnyAction => {
  return action(ApiActionTypes.RECEIVE_PROFILE, profile)
}

export const enterPage = (pageId: string, value: string): AnyAction => {
  return action(ApiActionTypes.ENTER_PAGE, { pageId, value })
}

export const userInteract = (
  pageId: string,
  buttonId: string,
  value: string
): AnyAction => {
  return action(ApiActionTypes.USER_INTERACT, { pageId, buttonId, value })
}

const requestUserEvent = async (events: ActionProps[], state: ApiState) => {
  const { token } = state
  try {
    const response = await fetch(trackingApi, {
      method: 'POST',
      body: JSON.stringify(events),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    const json = await response.json()
    return json
  } catch (error) {
    console.log('ERROR:', error)
  }
}

export const reducer = (
  state: ApiState = initialState,
  action: AnyAction
): ApiState => {
  switch (action.type) {
    case ApiActionTypes.RECEIVE_AUTH: {
      return { ...state, token: action.payload }
    }
    case ApiActionTypes.RECEIVE_PROFILE: {
      return { ...state, ...action.payload }
    }
    case ApiActionTypes.ENTER_PAGE: {
      const { pageId, value } = action.payload
      const pageView: ActionProps = {
        attendeeID: state.attendeeID,
        userAction: 'EnterPage',
        currentPage: pageId,
        value,
        UtcAdded: new Date().toISOString(),
      }
      const userPageViews = [...state.userPageViews, pageView]
      const lastPageView = state.userPageViews[state.userPageViews.length - 1]
      const dwellTimeEvent = lastPageView && {
        attendeeID: state.attendeeID,
        userAction: 'TimeOnPage',
        currentPage: lastPageView.currentPage,
        value: Math.abs(
          Math.round((+new Date() - +new Date(lastPageView.UtcAdded)) / 1000)
        ).toString(),
        UtcAdded: new Date().toISOString(),
      }
      const userDwellTimes = lastPageView
        ? [...state.userDwellTimes, dwellTimeEvent]
        : state.userDwellTimes
      state.token &&
        requestUserEvent(
          [
            {
              ...dwellTimeEvent,
              attendeeID: state.attendeeID,
            },
            {
              ...pageView,
              attendeeID: state.attendeeID,
            },
          ],
          state
        )
      return { ...state, userPageViews, userDwellTimes }
    }
    case ApiActionTypes.USER_INTERACT: {
      const { pageId, buttonId, value } = action.payload
      const interactionEvent: ActionProps = {
        attendeeID: state.attendeeID,
        userAction: buttonId,
        currentPage: pageId,
        value,
        UtcAdded: new Date().toISOString(),
      }
      requestUserEvent([interactionEvent], state)
      return {
        ...state,
        userInteractions: [...state.userInteractions, interactionEvent],
      }
    }
    default:
      return { ...state }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const submitDemoForm = async (data: any, state: ApiState) => {
  const { attendeeID, token } = state
  try {
    const response = await fetch(scheduleDemo, {
      method: 'POST',
      body: JSON.stringify({
        attendeeID,
        ...data,
        utcAdded: new Date().toISOString(),
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    const json = await response.json()
    return json
  } catch (error) {
    console.log('ERROR:', error)
  }
}

const parseSearch = (search: string) => {
  const searchObj = queryString.parse(search)
  const rtnObj = {}
  for (const key in searchObj) {
    const value = searchObj[key] || ''
    rtnObj[camelCase(key)] = value
  }
  return rtnObj
}

const APIProvider: React.FC<Props> = ({ children }): React.ReactElement => {
  const [initialized, setInitialized] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const { location } = useContext(LayoutContext)

  const initialProfile = parseSearch(location.search) as ProfileState
  // console.log('initialProfile', initialProfile)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requestToken = async () => {
    // console.log(searchParams)
    try {
      const response = await fetch(getToken, {
        method: 'POST',
        body: JSON.stringify({
          grant_type: grantType,
          client_id: clientId,
          client_secret: clientSecret,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const json = await response.json()
      const token = json.access_token as string
      dispatch(receiveAuth(token))
      return json.access_token
    } catch (error) {
      console.log('ERROR:', error)
    }
  }

  const requestProfile = async (
    token: string,
    initialProfile: ProfileState
  ) => {
    try {
      const response = await fetch(`${apiRoot}api/attendee`, {
        method: 'POST',
        body: JSON.stringify({
          ...initialProfile,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const json = await response.json()
      const profile = { ...json, attendeeID: json.id }
      dispatch(receiveProfile(profile))
      return json
    } catch (error) {
      console.log('ERROR:', error)
    }
  }

  useEffect(() => {
    if (initialized) return
    dispatch(receiveProfile(initialProfile))
    setInitialized(true)
    const init = async () => {
      const token = await requestToken()
      await requestProfile(token, initialProfile)
    }
    init()
  })

  return (
    <Context.Provider
      value={{
        submitDemoForm: (data: any) => submitDemoForm(data, state),
        enterPage: (pageId: string, value: string) =>
          dispatch(enterPage(pageId, value)),
        userInteract: (pageId: string, buttonId: string, value: string) =>
          dispatch(userInteract(pageId, buttonId, value)),
        state,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default APIProvider
