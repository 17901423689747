/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion } from 'framer-motion'

interface Props {
  children?: React.ReactNode
  className?: string
  location: Location
}

const GlobalBackground: React.FC<Props> = ({
  className,
  location,
}): React.ReactElement => {
  const { pathname } = location
  const backgroundColor = pathname === '/' ? '#000' : '#BFC4C8'

  return (
    <motion.div
      className={className}
      sx={{ backgroundColor }}
      animate={{ backgroundColor }}
    />
  )
}

export default GlobalBackground
