/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion } from 'framer-motion'
import { ReactSVG } from 'react-svg'
import CloseGfx from 'components/ui/icons/inline/close.svg'

interface Props {
  className?: string
  onClick?: () => void
}

const CloseBtn: React.FC<Props> = ({
  onClick,
  className,
}): React.ReactElement => {
  return (
    <motion.button
      className={className}
      sx={{
        borderWidth: '.1rem',
        borderRadius: '.3rem',
        borderStyle: 'solid',
        borderColor: 'muted',
        width: 'auto',
        m: '.2rem',
      }}
      onClick={onClick}
    >
      <CloseGfx
        sx={{
          width: '1.8rem',
          height: '1.8rem',
          maxWidth: '100%',
          maxHeight: '100%',
          p: '.2rem',
          path: {
            fill: 'primary',
          },
        }}
      />
    </motion.button>
  )
}

export default CloseBtn
