// Example for using discriminated union types.
export type ThemeColors = 'light' | 'dark'
export type HeaderIsStuck = true | false
export type MenuIsExpanded = -1

// Use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export enum LayoutActionTypes {
  SET_THEME = '@@layout/SET_THEME',
  SET_HEADER_IS_STUCK = '@@layout/SET_HEADER_STICKY',
  SET_MENU_IS_EXPANDED = '@@layout/SET_MENU_IS_EXPANDED',
  SET_MODAL_SLIDES = '@@layout/SET_MODAL_SLIDES',
  SET_SUBPAGE_MODAL_SLIDES = '@@layout/SET_SUBPAGE_MODAL_SLIDES',
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface LayoutState {
  readonly theme: ThemeColors
  readonly headerIsStuck: HeaderIsStuck
  readonly menuIsExpanded: MenuIsExpanded
  readonly modalSlides: string[] | null
  readonly subpageModalSlides: React.FC[]
}
