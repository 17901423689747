import { Page } from 'store/pages'

export enum PageIds {
  INDEX = 'index',
  PROFILE = 'profile',
  EXPLORE = 'explore',
  ANALYZE = 'analyze',
  DEMO = 'demo',
}

export const IndexPage: Page = {
  heading: '',
  label: 'Watch Video',
  path: '/',
  subhead: '',
}

export const ProfilePage: Page = {
  heading: '',
  label: 'Build Profile',
  path: '/profile',
  subhead: '',
}

export const ExplorePage: Page = {
  heading: '',
  label: 'Explore Apps',
  path: '/explore',
  subhead: '',
}

export const AnalyzePage: Page = {
  heading: '',
  label: 'Analyze Data',
  path: '/analyze',
  subhead: '',
}

export const DemoPage: Page = {
  heading: '',
  label: 'Schedule Demo',
  path: '/demo',
  subhead: '',
}

export const map: Map<string, Page> = new Map([
  [PageIds.INDEX, IndexPage],
  [PageIds.PROFILE, ProfilePage],
  [PageIds.EXPLORE, ExplorePage],
  [PageIds.ANALYZE, AnalyzePage],
  [PageIds.DEMO, DemoPage],
])
