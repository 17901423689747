import steps from './steps'
import { map as analytes } from './analytes'
import { map as samples } from './samples'
import { map as regions } from './regions'
import { map as workflows } from './workflows'
import { map as assays } from './assays'
import { map as caseStudies } from './caseStudies'
import { StepNode } from './types'

export const nodes: Map<string, StepNode> = new Map([
  ...analytes,
  ...samples,
  ...regions,
  ...workflows,
  ...assays,
  ...caseStudies,
])
export { steps }
