/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useContext } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import SubPageArrowBtn from '../SubPageArrowBtn'
import { ApplicationState } from 'store'
import * as profileActions from 'store/profile/actions'
import { connect, useDispatch } from 'react-redux'
import { Page } from 'store/pages'
import { Context } from 'components/Layout'
import { Context as APIContext } from 'components/APIProvider'
interface Props {
  location?: Location
  previousPage?: Page
  nextPage?: Page
  selections?: string[]
}

const SubPageLeftRightBtns: React.FC<Props> = ({
  location,
  previousPage,
  nextPage,
  selections,
}): React.ReactElement => {
  const { userInteract } = useContext(APIContext)
  const dispatch = useDispatch()
  const { pathname } = location
  // const left = pathname === '/' ? '-50%' : 0
  // const right = pathname === '/' ? '-50 : 0
  // console.log('LEFT/RIGHT', previousPage, nextPage)
  const navIsShown = pathname !== '/'
  const showNextBtn = navIsShown && nextPage
  return (
    <Context.Consumer>
      {context => (
        <AnimatePresence>
          {navIsShown && (
            <motion.div
              key="left-nav-arrow"
              sx={{ position: 'absolute', top: '43%' }}
              animate={{}}
              exit={{}}
            >
              <SubPageArrowBtn
                sx={{
                  zIndex: 10,
                  position: 'absolute',
                  top: '43%',
                  // width: context.isLandscape ? '5vw' : '8.5vw',
                }}
                onClick={previousPage?.gotoFunction}
                pointLeft
              >
                {previousPage?.label}
              </SubPageArrowBtn>
            </motion.div>
          )}
          {showNextBtn && (
            <motion.div
              key="right-nav-arrow"
              sx={{ right: 0, position: 'absolute', top: '43%' }}
              animate={{}}
            >
              <SubPageArrowBtn
                sx={{
                  zIndex: 10,
                  position: 'absolute',
                  // right: 0,
                  top: '43%',
                  // width: context.isLandscape ? '5vw' : '8.5vw',
                  // transform: 'scaleX(-1)',
                }}
                onClick={() => {
                  userInteract(pathname, nextPage?.label, selections.toString())
                  nextPage?.gotoFunction()
                }}
              >
                {nextPage?.label}
              </SubPageArrowBtn>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </Context.Consumer>
  )
}

const mapStateToProps = ({ pages, profile }: ApplicationState) => ({
  previousPage: pages.previousPage,
  nextPage: pages.nextPage,
  selections: profile.selections,
})

export default connect(mapStateToProps)(SubPageLeftRightBtns)
