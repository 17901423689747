import { action } from 'typesafe-actions'

import {
  LayoutActionTypes,
  ThemeColors,
  HeaderIsStuck,
  MenuIsExpanded,
} from './types'
import { MediaPack } from 'content/profile/types'

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.

export const setTheme = (theme: ThemeColors) =>
  action(LayoutActionTypes.SET_THEME, theme)

export const setHeaderIsStuck = (headerIsStuck: HeaderIsStuck) =>
  action(LayoutActionTypes.SET_HEADER_IS_STUCK, headerIsStuck)

export const setMenuIsExpanded = (menuIsExpanded: MenuIsExpanded) =>
  action(LayoutActionTypes.SET_MENU_IS_EXPANDED, menuIsExpanded)

export const setModalSlides = (slides: (string | React.FC)[]) =>
  action(LayoutActionTypes.SET_MODAL_SLIDES, slides)

export const setSubpageModalSlides = (slides: React.FC<MediaPack>[]) =>
  action(LayoutActionTypes.SET_SUBPAGE_MODAL_SLIDES, slides)
