import { StepNode, NodeTypes } from './types'
import { SampleIds } from './samples'
import { AnalyteIds } from './analytes'
import geoMedia from './media/regions/geo.png'
import segmentMedia from './media/regions/segment.png'
import rareMedia from './media/regions/rare.png'
import griddedMedia from './media/regions/gridded.png'
import contourMedia from './media/regions/contour.png'

import rareDetail from './media/regions/rare-detail.png'
import contourDetail from './media/regions/contour-detail.png'
import geoDetail from './media/regions/geometric-detail.png'
import segmentDetail from './media/regions/segment-detail.png'

export enum RegionIds {
  GEO = 'geometric-profiling',
  SEGMENT = 'segment-profiling',
  CONTOUR = 'contour-profiling',
  GRID = 'gridded-profiling',
  RARE = 'rare-cell-profiling',
}

export const GeometricProfiling: StepNode = {
  id: RegionIds.GEO,
  type: NodeTypes.REGION,
  label: 'Geometric Profiling',
  rules: [
    [AnalyteIds.PROTEIN, SampleIds.SMT],
    [AnalyteIds.RNA, SampleIds.SMT],
  ],
  media: geoMedia,
  detail: geoDetail,
}
export const SegmentProfiling: StepNode = {
  id: RegionIds.SEGMENT,
  type: NodeTypes.REGION,
  label: 'Segment Profiling',
  rules: [[AnalyteIds.RNA, SampleIds.SMT]],
  media: segmentMedia,
  detail: segmentDetail,
}
export const ContourProfiling: StepNode = {
  id: RegionIds.CONTOUR,
  type: NodeTypes.REGION,
  label: 'Contour Profiling',
  rules: [[AnalyteIds.RNA, SampleIds.SMT]],
  media: contourMedia,
  detail: contourDetail,
}
export const GriddedProfiling: StepNode = {
  id: RegionIds.GRID,
  type: NodeTypes.REGION,
  label: 'Gridded Profiling',
  rules: [],
  media: griddedMedia,
}
export const RareCellProfiling: StepNode = {
  id: RegionIds.RARE,
  type: NodeTypes.REGION,
  label: 'Rare Cell Profiling',
  rules: [
    [AnalyteIds.PROTEIN, SampleIds.SMT],
    [AnalyteIds.PROTEIN, SampleIds.TMA],
  ],
  media: rareMedia,
  detail: rareDetail,
}

export const map: Map<string, StepNode> = new Map([
  [RegionIds.RARE, RareCellProfiling],
  [RegionIds.GRID, GriddedProfiling],
  [RegionIds.CONTOUR, ContourProfiling],
  [RegionIds.SEGMENT, SegmentProfiling],
  [RegionIds.GEO, GeometricProfiling],
])
