import { StepNode, NodeTypes } from './types'
import { AnalyteIds } from './analytes'
import cnbGfx from './media/samples/cnb.png'
import fftGfx from './media/samples/fft.png'
import smtGfx from './media/samples/smt.png'
import tbffpeBankGfx from './media/samples/tbffpebank.png'
import tmaGfx from './media/samples/tma.png'

export enum SampleIds {
  SMT = 'slide-mounted-tissue',
  CNB = 'core-needle-biopsy',
  TMA = 'tissue-microarray',
  TBB = 'tissue-block-ffpe-bank',
  FFT = 'fresh-frozen-tissue',
}

export const SlideMountedTissue: StepNode = {
  id: SampleIds.SMT,
  type: NodeTypes.SAMPLE,
  label: 'Slide Mounted Tissue',
  rules: [[AnalyteIds.PROTEIN], [AnalyteIds.RNA]],
  media: smtGfx,
}
export const CoreNeedleBiopsy: StepNode = {
  id: SampleIds.CNB,
  type: NodeTypes.SAMPLE,
  label: 'Core Needle Biopsy',
  rules: [],
  media: cnbGfx,
}
export const TissueMicroarray: StepNode = {
  id: SampleIds.TMA,
  type: NodeTypes.SAMPLE,
  label: 'Tissue Microarray',
  rules: [[AnalyteIds.PROTEIN]],
  media: tmaGfx,
}
export const TissueBlockFFPEBank: StepNode = {
  id: SampleIds.TBB,
  type: NodeTypes.SAMPLE,
  label: 'Tissue Block FFPE Bank',
  rules: [],
  media: tbffpeBankGfx,
}
export const FreshFrozenTissue: StepNode = {
  id: SampleIds.FFT,
  type: NodeTypes.SAMPLE,
  label: 'Fresh Frozen Tissue',
  rules: [],
  media: fftGfx,
}

export const map: Map<string, StepNode> = new Map([
  [SampleIds.FFT, FreshFrozenTissue],
  [SampleIds.TBB, TissueBlockFFPEBank],
  [SampleIds.TMA, TissueMicroarray],
  [SampleIds.CNB, CoreNeedleBiopsy],
  [SampleIds.SMT, SlideMountedTissue],
])
