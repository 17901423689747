/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion } from 'framer-motion'
import ZoomGfx from 'components/ui/icons/inline/zoom.svg'
import { useDispatch } from 'react-redux'
import { setModalSlides } from 'store/layout'

interface Props {
  children?: React.ReactNode
  className?: string
  src: string | React.FC
  preview?: string
  position?: string
}

const PreviewImg: React.FC<{ src: string; className?: string }> = ({
  src,
  className,
}) => {
  return (
    <img
      src={src}
      sx={{
        width: 'auto',
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
      }}
      className={className}
    />
  )
}

const ZoomableImage: React.FC<Props> = ({
  children,
  className,
  src,
  preview,
  position,
}): React.ReactElement => {
  const dispatch = useDispatch()
  return (
    <motion.button
      className={className}
      sx={{
        position: 'relative',
        width: preview ? '100%' : '1.2rem',
        height: preview ? '100%' : '1.2rem',
        p: '.5rem',
      }}
      onClick={() => dispatch(setModalSlides([src]))}
    >
      {preview && <PreviewImg src={preview} sx={{}} />}
      <ZoomGfx
        sx={{
          width: '1.2rem',
          height: '1.2rem',
          position: 'absolute',
          top: 0,
          right: 0,
          // top: 2,
          // right: preview ? '-1.2rem' : 0,
        }}
      />
    </motion.button>
  )
}

export default ZoomableImage
