import { base } from '@theme-ui/presets'

export default {
  ...base,
  colors: {
    ...base.colors,
    primary: '#194983',
    secondary: '#AAD160',
    muted: '#DFDFDF',
    anchor: '#974B97',
    hover: '#951B95',
    overlay: '#121212',
  },
  buttons: {
    primary: {
      border: 'none',
      backgroundColor: 'primary',
      color: '#fff',
      fontSize: '1rem',
      fontWeight: '500',
      py: ['.5rem', '1.5rem'],
      px: ['1.3rem', '2.3rem'],
      borderRadius: 5,
      cursor: 'pointer',
      transition: 'all .3s ease',
      '&:hover': {
        backgroundColor: 'secondary',
      },
    },
    standard: {
      backgroundColor: '#fff',
      color: 'primary',
      px: '1.6rem',
      py: '.7rem',
      m: '-.5rem',
      fontSize: '.85rem',
      display: 'flex',
      fontWeight: '600',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      borderStyle: 'solid',
      borderWidth: '.2rem',
      borderRadius: '.3rem',
      overflow: 'hidden',
      borderColor: 'primary',
      // maxWidth: '10rem',
      // fontWeight: 'bold',
      '&.active,&:hover': {
        backgroundColor: 'primary',
        color: '#fff',
      },
      transition: 'all .3s ease',
      img: {
        maxWidth: ['2rem', null, '2rem'],
        maxHeight: ['2rem', null, '2rem'],
      },
    },
  },
  styles: {
    ...base.styles,

    root: {
      '.highlighted': { color: 'secondary' },
      button: {
        ':focus': { outline: 0 },
        fontFamily: 'inherit',
      },
      fontFamily: '"Gotham", sans-serif',
      h2: {
        fontSize: '1.8rem',
        fontWeight: 600,
      },
      h3: {
        fontSize: '1.4rem',
        fontWeight: 600,
      },
      h4: {
        fontSize: '1rem',
        color: 'primary',
        fontWeight: 600,
      },
    },
  },
  forms: {
    input: {
      borderStyle: 'solid',
      borderWidth: '.12rem',
      borderRadius: '.4rem',
      borderColor: 'primary',
      backgroundColor: '#fff',
      color: 'primary',
      fontWeight: 600,
      fontSize: '.8rem',
      '::placeholder': {
        color: '#C6C6C6',
      },
    },
    select: {
      borderStyle: 'solid',
      borderWidth: '.12rem',
      borderRadius: '.4rem',
      borderColor: 'primary',
      backgroundColor: '#fff',
      color: 'primary',
    },
  },
}
