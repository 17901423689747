/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import fadeAnimations from 'animations/fade'

import exampleImage1 from 'content/profile/media/case-studies/rimm-detail1.jpg'
import exampleImage2 from 'content/profile/media/case-studies/rimm-detail2.jpg'
import exampleImage3 from 'content/profile/media/case-studies/rimm-detail3.jpg'
import exampleImage4 from 'content/profile/media/case-studies/rimm-detail4.jpg'
import CloseBtn from 'components/CloseBtn'
import { ApplicationState } from 'store'
import { connect, useDispatch } from 'react-redux'
import { LayoutActionTypes, setModalSlides } from 'store/layout'
import GalleryArrowBtn from 'components/GalleryArrowBtn'

interface Props {
  className?: string
  slides?: string[] | React.FC[]
  index?: number
}

const ViewLarger: React.FC<Props> = ({
  className,
  slides = [],
  index = 0,
}): React.ReactElement => {
  const [slideIndex, setSlideIndex] = useState(index)
  const getNextSlide = slideIndex + 1 > slides?.length - 1 ? 0 : slideIndex + 1
  const getPreviousSlide =
    slideIndex - 1 < 0 ? slides?.length - 1 : slideIndex - 1
  const dispatch = useDispatch()
  const MediaComponent: React.FC =
    typeof slides[slideIndex] === 'string'
      ? () => (
          <img
            src={slides[slideIndex] as string}
            sx={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        )
      : (slides[slideIndex] as React.FC)
  return (
    <AnimatePresence>
      {slides.length > 0 && (
        <motion.div
          key={JSON.stringify(slides)}
          className={className}
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            position: 'absolute',
            zIndex: 10,
            top: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          {...fadeAnimations}
        >
          <div
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              position: 'absolute',
              backgroundColor: '#000',
              opacity: 0.7,
              top: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></div>
          <div
            sx={{
              display: 'flex',
              width: '90%',
              height: '90%',
              position: 'absolute',
              backgroundColor: '#fff',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              borderRadius: '.4rem',
            }}
          >
            <div
              sx={{
                position: 'relative',
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  key={slides[slideIndex]}
                  {...fadeAnimations}
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <MediaComponent
                    sx={{ width: '100%', height: '100%', p: '4rem' }}
                  />
                </motion.div>
              </AnimatePresence>
            </div>
            <AnimatePresence>
              {slides.length > 1 && (
                <motion.div
                  sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    flex: 0,
                    justifyContent: 'center',
                  }}
                >
                  <GalleryArrowBtn
                    pointLeft
                    onClick={() => setSlideIndex(getPreviousSlide)}
                  />

                  <GalleryArrowBtn
                    onClick={() => setSlideIndex(getNextSlide)}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <CloseBtn
              sx={{ position: 'absolute', top: 0, right: 0 }}
              onClick={() => dispatch(setModalSlides(null))}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const mapStateToProps = ({ layout }: ApplicationState) => ({
  slides: layout.modalSlides,
})

export default connect(mapStateToProps)(ViewLarger)
