/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import ZoomableImage from 'components/ZoomableImage'

import img1 from './lesinski-detail1.png'
import img2 from './lesinski-detail2.png'
import img3 from './lesinski-detail3.png'
import img4 from './lesinski-detail4.jpg'

import arrowUp from './ArrowUp.png'
import arrowDown from './ArrowDown.png'

import fadeAnimations from 'animations/fade'
import ReadMoreLink from 'components/CaseStudyDetails/ReadMoreLink'
import { MediaPack } from 'content/profile/types'
import { css } from '@emotion/core'
import { Context } from 'components/Layout'

const commonFont = css({
  fontSize: '15px',
})

const commonIndent = css({
  textIndent: '25px',
  color: 'primary',
  lineHeight: '1.5em',
})

const arrowPointsCSS = css({
  width: '25px',
  height: '25px',
})
const arrowFlexCSS = css({
  display: 'flex',
  width: '100%',
  paddingLeft: '3em',
})
const FrontPage: React.FC<MediaPack> = ({
  readMore,
  index,
}): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            h5: { p: '1rem' },
            width: '100%',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
          {...fadeAnimations}
        >
          <h5>Immune Biology of Colorectal Tumors</h5>
          <div
            sx={{
              display: 'flex',
              // alignItems: context.isLandscape ? 'row' : 'column',
              alignItems: 'column',
              width: '100%',
            }}
          >
            <div
              sx={{
                textAlign: 'center',
                fontSize: '.7rem',
                mr: '2rem',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                marginRight: '0',
              }}
            >
              <div sx={{ display: 'flex', flexDirection: 'column' }}>
                <div sx={{ width: '100%' }}>
                  <ZoomableImage
                    preview={img1}
                    src={img1}
                    sx={{ maxWidth: context.isLandscape ? '65%' : '100%' }}
                  />
                </div>
              </div>
            </div>
            <div
              sx={{
                color: 'primary',
                fontSize: '1rem',
                textAlign: 'left',
                width: '65%',
              }}
            >
              <p css={commonFont}>Greg Lesinski, PhD, MPH, Emory University</p>
              <br></br>
              <p css={commonFont}>
                Immunotherapies have shown great promise in a subset of patients
                with certain malignancies in clinical trials, but not Pancreatic
                Ductal Adenocarcinoma (PDAC) Immunotherapies have been initially
                tested as single agents.
              </p>
              <br></br>
              <p css={commonFont}>
                How does co-treatment with relevant chemo- or radiotherapy
                regimens impact T cell checkpoint blockade?
              </p>
              <br></br>
              {/* This link will be displayed if the 'readMore' function is passed.
             It is how you open up the pages defined below */}
              {readMore && <ReadMoreLink readMore={readMore} />}
            </div>
          </div>
        </motion.div>
      )}
    </Context.Consumer>
  )
}

export const Page1: React.FC = (): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{ display: 'flex', flexDirection: 'column' }}
          {...fadeAnimations}
        >
          <div sx={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
            <h3 sx={{ color: 'primary' }}>
              Your <span className="highlighted">RNA</span> Case Study Endpoint:{' '}
              <span className="highlighted">Neoadjuvant Response</span>
            </h3>
            <br />
            <h4 sx={{ color: 'black' }}>
              Sampling different regions to understand tumor biology
            </h4>

            <div
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  paddingTop: '1.5em',
                  paddingBottom: '2.5em',
                }}
              >
                <div sx={{ width: '50%', paddingLeft: '1.5em' }}>
                  <p sx={{ fontWeight: 'bold', color: 'primary' }}>
                    ROI Selection Strategy
                  </p>
                  <ul>
                    <li css={commonIndent}>
                      <span sx={{ color: 'secondary' }}>• </span>Tumor Enriched
                    </li>
                    <li css={commonIndent}>
                      <span sx={{ color: 'secondary' }}>• </span>Immune Enriched
                    </li>
                    <li css={commonIndent}>
                      <span sx={{ color: 'secondary' }}>• </span>Stroma Enriched
                    </li>
                  </ul>
                </div>

                <div sx={{ width: '50%' }}>
                  <p css={commonFont}>
                    To determine the immun- ological effect of neoadjuvant
                    therapy (FOLFIRINOX) in PDAC distinct regions of the tumor
                    and the tumor microenvironment were chosen.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div sx={{ img: { width: '45%' } }}>
            <ZoomableImage preview={img2} src={img2} />
          </div>
        </motion.div>
      )}
    </Context.Consumer>
  )
}

export const Page2: React.FC = (): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
          }}
          {...fadeAnimations}
        >
          <div sx={{ paddingLeft: '3rem', paddingBottom: '3rem' }}>
            <h3 sx={{ color: 'primary' }}>
              Your <span className="highlighted">RNA</span> Case Study Endpoint:{' '}
              <span className="highlighted">Neoadjuvant Response</span>
            </h3>
            <br />
            <h4 sx={{ color: 'black' }}>
              Clustering of protein expression across tumor compartments
            </h4>
          </div>
          <div
            sx={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              img: { width: '85%' },
            }}
          >
            <ZoomableImage preview={img3} src={img3} />
          </div>
        </motion.div>
      )}
    </Context.Consumer>
  )
}

export const Page3: React.FC = (): React.ReactElement => {
  return (
    <Context.Consumer>
      {context => (
        <motion.div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            padding: '3rem',
          }}
          {...fadeAnimations}
        >
          <h3 sx={{ color: 'primary' }}>
            Your <span className="highlighted">RNA</span> Case Study Endpoint:{' '}
            <span className="highlighted">Neoadjuvant Response</span>
          </h3>
          <br />
          <h4 sx={{ paddingBottom: '1rem', color: 'black' }}>
            FOLFIRINOX treatment results in significant spatially-distinct
            changes in protein expression
          </h4>
          <h5 sx={{ paddingBottom: '20px', color: 'primary' }}>
            FOLFIRINOX treatment is associated with:
          </h5>
          <div
            sx={{
              textAlign: 'left',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <div sx={{ width: '50%' }}>
              <ul>
                <li css={commonIndent} sx={{ color: 'primary' }}>
                  <span sx={{ color: 'secondary' }}>• </span>Tumor Enriched
                </li>
                <div css={arrowFlexCSS} sx={{ paddingBottom: '0.8em' }}>
                  <motion.img src={arrowUp} css={arrowPointsCSS} />
                  <div sx={{ paddingLeft: '10px' }}>
                    <span css={commonFont}>
                      CD45, CD3, CD45RO, CD56, BCL2, PTEN
                    </span>
                  </div>
                </div>

                <div css={arrowFlexCSS}>
                  <motion.img src={arrowDown} css={arrowPointsCSS} />
                  <p css={commonFont}>β-Catenin</p>
                </div>

                <li css={commonIndent} sx={{ color: 'primary' }}>
                  <span sx={{ color: 'secondary' }}>• </span>Immune Enriched
                </li>

                <div css={arrowFlexCSS} sx={{ paddingBottom: '0.8em' }}>
                  <motion.img src={arrowUp} css={arrowPointsCSS} />
                  <p css={commonFont}>
                    CD14, B7H3, PD-L1, Granzyme B, pAKT, PTEN, β-Catenin, pSTAT3
                  </p>
                </div>

                <div css={arrowFlexCSS}>
                  <motion.img src={arrowDown} css={arrowPointsCSS} />
                  <p css={commonFont}>CD19, CD20, CD4</p>
                </div>

                <li css={commonIndent} sx={{ color: 'primary' }}>
                  <span sx={{ color: 'secondary' }}>• </span>Stroma Enriched
                </li>

                <div css={arrowFlexCSS} sx={{ paddingBottom: '0.8em' }}>
                  <motion.img src={arrowUp} css={arrowPointsCSS} />
                  <p css={commonFont}>BCL2, pSTAT3</p>
                </div>

                <div css={arrowFlexCSS}>
                  <motion.img src={arrowDown} css={arrowPointsCSS} />
                  <p css={commonFont}>CD3, CD68, VISTA, Ki67</p>
                </div>
              </ul>
            </div>
            <div sx={{ img: { width: '50%' } }}>
              <ZoomableImage preview={img4} src={img4} />
            </div>
          </div>
        </motion.div>
      )}
    </Context.Consumer>
  )
}
interface CaseStudyProps {
  children?: React.ReactNode
  className?: string
  readMore?: Function
  nextPage?: Function
  previousPage?: Function
  index?: number
  pages?: React.FC[]
}

const CaseStudy: React.FC<CaseStudyProps> = ({
  className,
  readMore,
  index = -1,
  nextPage,
  previousPage,
  pages = [Page1, Page2, Page3],
}): React.ReactElement => {
  const gotoNextPage = () => (index >= pages.length - 1 ? 0 : index + 1)
  const gotoPrevPage = () => (index <= 0 ? index - 1 : pages.length - 1)
  const Page = index < 0 ? FrontPage : pages[index]
  return (
    <motion.div
      {...fadeAnimations}
      className={className}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AnimatePresence>
        {
          <Page
            key={index}
            readMore={(): React.FC<MediaPack>[] => readMore(pages)}
          />
        }
      </AnimatePresence>
    </motion.div>
  )
}

export default CaseStudy
