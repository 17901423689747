import React from 'react'
import { Provider } from 'react-redux'
import configureStore from './configureStore'

const initialState =
  typeof window !== 'undefined' ? window.INITIAL_REDUX_STATE : {}
const store = configureStore(initialState)

const ReduxWrapper = ({ element }: { element: React.ReactElement }) => (
  <Provider store={store}>{element}</Provider>
)

export default ReduxWrapper
