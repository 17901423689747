import { Reducer } from 'redux'
import { LayoutState, LayoutActionTypes } from './types'

export const initialState: LayoutState = {
  theme: 'light',
  headerIsStuck: false,
  menuIsExpanded: -1,
  modalSlides: [],
  subpageModalSlides: [],
}

const reducer: Reducer<LayoutState> = (state = initialState, action) => {
  switch (action.type) {
    case LayoutActionTypes.SET_THEME: {
      return { ...state, theme: action.payload }
    }
    case LayoutActionTypes.SET_HEADER_IS_STUCK: {
      return { ...state, headerIsStuck: action.payload }
    }
    case LayoutActionTypes.SET_MENU_IS_EXPANDED: {
      return { ...state, menuIsExpanded: action.payload }
    }
    case LayoutActionTypes.SET_MODAL_SLIDES: {
      let modalSlides = action.payload || []
      if (typeof action.payload === 'string') modalSlides = [action.payload]
      return { ...state, modalSlides }
    }
    case LayoutActionTypes.SET_SUBPAGE_MODAL_SLIDES: {
      const subpageModalSlides = action.payload || []
      return { ...state, subpageModalSlides }
    }
    default: {
      return state
    }
  }
}

export { reducer as layoutReducer }
