// Example for using discriminated union types.
// export type ThemeColors = 'light' | 'dark'
// export type HeaderIsStuck = true | false
// export type MenuIsExpanded = -1

import { Step } from 'content/profile/types'

// Use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export enum ActionTypes {
  SET_STEP_INDEX = '@@profile/SET_STEP_INDEX',
  NEXT_STEP = '@@profile/NEXT_STEP',
  PREVIOUS_STEP = '@@profile/PREVIOUS_STEP',
  SET_STEP_SELECTION = '@@profile/SET_STEP_SELECTION',
  // SET_STEPS = '@@profile/SET_STEPS',
  // SET_ANALYTE = '@@profile/SET_ANALYTE',
  // SET_SAMPLE = '@@profile/SET_SAMPLE',
  // SET_REGION_OF_INTEREST = '@@profile/SET_REGION_OF_INTEREST',
  // SET_WORKFLOW = '@@profile/SET_WORKFLOW',
  // SET_ASSAY = '@@profile/SET_ASSAY',
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface State {
  readonly stepIndex: number
  readonly maxIndex: number
  readonly steps: Step[]
  readonly selections: string[]
  readonly analyte: string | null
  readonly sample: string | null
  readonly region: string | null
  readonly workflow: string | null
  readonly assay: string | null
  readonly caseStudy: string | null
}
