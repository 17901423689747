/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React, { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { StepNodeComponent } from '../../types'
import WorkflowAnimation from './WorkflowAnimation'

const ProteinWorkflow: React.FC<StepNodeComponent> = ({
  children,
  className,
  delay = 0.3,
}): React.ReactElement => {
  return <WorkflowAnimation analyte="protein" delay={delay} />
}

export default ProteinWorkflow
